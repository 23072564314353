import { satisfaction as content } from '../../static/content/satisfaction';

import './satisfaction.scss';

const Satisfaction = () => (
  <section className="satisfaction">
    <div className="container">
      <h2 className="section-title" dangerouslySetInnerHTML={{ __html: content.title }} />

      <div className="satisfaction__banners">
        {content.badges.map((el, index) => (
          <a href={el.link} rel="noReferrer" target="_blank" className="satisfaction__banners--item" key={index}>
            <div>
              <img src={el.icon} alt={el.alt} />
            </div>

            <div className="satisfaction__banners--item__stars">
              <img src="/assets/images/icons/red_star.svg" alt="star" />
              <img src="/assets/images/icons/red_star.svg" alt="star" />
              <img src="/assets/images/icons/red_star.svg" alt="star" />
              <img src="/assets/images/icons/red_star.svg" alt="star" />
              <img src="/assets/images/icons/red_star.svg" alt="star" />
            </div>
          </a>
        ))}
      </div>
    </div>
  </section>
);

export default Satisfaction;
