import { OrderForm } from '../../components';

import './getYourQuote.scss';

const GetYourQuote = () => (
  <section className="get-your-quote">
    <div className="container">
      <h2 className="section-title white">
        Get our Quote
        <br />
        Online!
      </h2>

      <OrderForm />
    </div>
  </section>
);

export default GetYourQuote;
