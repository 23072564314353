import { Link } from 'react-router-dom';

import { contacts } from '../../static/content/contacts';

import './hero.scss';

const Hero = () => (
  <section className="hero">
    <div className="hero__mobile" />
    <div className="container">
      <div className="hero__inner">
        <div className="hero__inner--content">
          <h1>
            Top-Rated Moving Services{` `}
            <br />
            in Temecula
          </h1>
          <p>We Can Move Anything</p>

          <div className="hero__inner--content__buttons">
            <Link to="/book-now" className="btn">
              Book a Service
            </Link>
            <a href={`tel:${contacts.phone}`} className="btn black phone">
              {contacts.phone}
            </a>
          </div>
        </div>

        <div className="hero__inner--image" />
      </div>
    </div>
  </section>
);

export default Hero;
