export const call_us = {
  title: `Contact us for your FREE<br/>in-home or virtual quote`,
  description: `Trust Space Moving to move your valuables all<br/>the way around the globe!`,
  payments: [
    {
      icon: '/assets/images/icons/mastercard.svg',
      alt: 'Mastercard',
    },
    {
      icon: '/assets/images/icons/visa.svg',
      alt: 'Visa',
    },
    {
      icon: '/assets/images/icons/discover.svg',
      alt: 'Discover',
    },
    {
      icon: '/assets/images/icons/ae.svg',
      alt: 'American Express',
    },
  ],
};
