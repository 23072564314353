export const services = {
  title: 'Moving Services',
  badges: [
    {
      icon: '/assets/images/icons/local_moving.svg',
      title: 'Local Moving',
    },
    {
      icon: '/assets/images/icons/commercial_moving.svg',
      title: 'Commercial Moving',
    },
    {
      icon: '/assets/images/icons/heavy_items_moving.svg',
      title: 'Heavy Items Moving',
    },
    {
      icon: '/assets/images/icons/antique_movers.svg',
      title: 'Antique Movers',
    },
    {
      icon: '/assets/images/icons/packing_services.svg',
      title: 'Packing Services',
    },
    {
      icon: '/assets/images/icons/storage.svg',
      title: 'Storage',
    },
  ],
};
