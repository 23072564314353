import React from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios from 'axios';

import 'react-datepicker/dist/react-datepicker.css';

import './orderForm.scss';
import { toast } from 'react-toastify';

const url = 'https://api.smartmoving.com/api/leads/from-provider?providerKey=3a333abb-abeb-4365-a15e-ab6e016edaa1';

const OrderForm = () => {
  const [orderData, setOrderData] = React.useState({
    destinationCity: '',
    destinationStreet: '',
    destinationZip: '',
    email: '',
    fullName: '',
    level: 'Level / Stairs',
    moveDate: new Date(),
    moveSize: '',
    notes: '',
    originCity: '',
    originStreet: '',
    originZip: '',
    packing: '',
    phoneNumber: '',
    referralSource: 'Temecula ADS',
  });

  const [errors, setErrors] = React.useState({
    destinationCity: true,
    destinationStreet: true,
    destinationZip: true,
    email: true,
    fullName: true,
    moveSize: true,
    notes: true,
    originCity: true,
    originStreet: true,
    originZip: true,
    packing: true,
    phoneNumber: true,
    moveDate: false,
    level: false,
    referralSource: false,
  });

  const [isDisabled, setDisabled] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    const areTruthy = Object.values(errors).every((value) => value !== true);

    setDisabled(!areTruthy);
  }, [errors]);

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setOrderData((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    if (val !== '') {
      setErrors((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [key]: true,
      }));
    }
  };

  const handleDatePicker = (e) => {
    setOrderData((prevState) => ({
      ...prevState,
      moveDate: e,
    }));
  };

  const submit = async () => {
    const date = orderData.moveDate.toLocaleDateString('en-US');

    const formData = {
      ...orderData,
      moveDate: date,
    };

    try {
      const response = await axios.post(url, formData);

      if (response.status === 200) {
        toast.success('We have received your data! Our manager will contact you shortly!');
        setOrderData({
          destinationCity: '',
          destinationStreet: '',
          destinationZip: '',
          email: '',
          fullName: '',
          level: 'Level / Stairs',
          moveDate: new Date(),
          moveSize: '',
          notes: '',
          originCity: '',
          originStreet: '',
          originZip: '',
          packing: '',
          phoneNumber: '',
          referralSource: 'Temecula ADS',
        });
        navigate('/thank-you');
      }
    } catch (error) {
      toast.error('Oooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <form className="order-form">
        <p>Contact information</p>
        <div>
          <input
            required
            type="text"
            name="fullName"
            placeholder="Full Name *"
            onChange={handleChange}
            value={orderData.fullName}
          />
          <input
            required
            type="tel"
            name="phoneNumber"
            placeholder="Phone *"
            onWheel={(e) => e.target.blur()}
            onChange={handleChange}
            value={orderData.phoneNumber}
            disabled={errors.fullName}
          />
          <input
            required
            type="email"
            name="email"
            placeholder="Email *"
            onChange={handleChange}
            value={orderData.email}
            disabled={errors.phoneNumber}
          />
        </div>

        <p>From</p>
        <div>
          <input
            required
            type="text"
            name="originStreet"
            placeholder="From Address *"
            onChange={handleChange}
            value={orderData.originStreet}
            disabled={errors.email}
          />
          <input
            required
            type="text"
            name="originCity"
            placeholder="City *"
            onChange={handleChange}
            value={orderData.originCity}
            disabled={errors.originStreet}
          />
          <input
            required
            type="number"
            name="originZip"
            placeholder="ZIP Code *"
            onWheel={(e) => e.target.blur()}
            onChange={handleChange}
            value={orderData.originZip}
            disabled={errors.originCity}
          />
        </div>

        <p>To</p>
        <div>
          <input
            required
            type="text"
            name="destinationStreet"
            placeholder="To Address"
            onChange={handleChange}
            value={orderData.destinationStreet}
            disabled={errors.originZip}
          />
          <input
            required
            type="text"
            name="destinationCity"
            placeholder="City *"
            onChange={handleChange}
            value={orderData.destinationCity}
            disabled={errors.destinationStreet}
          />
          <input
            required
            type="number"
            name="destinationZip"
            placeholder="ZIP Code"
            onWheel={(e) => e.target.blur()}
            onChange={handleChange}
            value={orderData.destinationZip}
            disabled={errors.destinationCity}
          />
        </div>

        <p>Moving Information</p>
        <div>
          <select name="moveSize" onChange={handleChange} value={orderData.moveSize} disabled={errors.destinationZip}>
            <option hidden>Moving Size *</option>
            <option value="Studio">Studio</option>
            <option value="1 bedroom">1 bedroom</option>
            <option value="2 bedroom">2 bedroom</option>
            <option value="3 bedroom">3 bedroom</option>
            <option value="4 bedroom">4 bedroom</option>
            <option value="5+ bedroom">5+ bedroom</option>
            <option value="Office">Office</option>
            <option value="Other">Other</option>
          </select>

          <select name="notes" onChange={handleChange} value={orderData.notes} disabled={errors.moveSize}>
            <option hidden>Type of residency *</option>
            <option value="Apartment">Apartment</option>
            <option value="House">House</option>
            <option value="Townhouse">Townhouse</option>
            <option value="Storage">Storage</option>
            <option value="Office">Office</option>
            <option value="Other">Other</option>
          </select>

          <select name="level" onChange={handleChange} value={orderData.level} disabled={errors.notes}>
            <option value="Level / Stairs">Level / Stairs</option>
            <option value="Ground Level">Ground Level</option>
            <option value="Some Stairs">Some Stairs</option>
            <option value="2nd fl/stairs">2nd fl/stairs</option>
            <option value="3nd fl/stairs">3nd fl/stairs</option>
            <option value="Elevator">Elevator</option>
          </select>

          <select name="packing" onChange={handleChange} value={orderData.packing} disabled={errors.moveSize}>
            <option hidden>Packing Service *</option>
            <option value="No Packing">No Packing</option>
            <option value="Some Stairs">Partial Packing</option>
            <option value="Full Packing">Full Packing</option>
          </select>

          <DatePicker
            selected={orderData.moveDate}
            onChange={handleDatePicker}
            withPortal
            dateFormat="MM/dd/yyyy"
            minDate={new Date()}
            disabled={errors.packing}
          />
        </div>

        <div>
          <input type="submit" value="Submit" className="btn white" onClick={submit} disabled={isDisabled} />
        </div>
      </form>
    </React.Fragment>
  );
};

export default OrderForm;
