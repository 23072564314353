import { services } from '../../static/content/services';

import './services.scss';

const Services = () => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">{services.title}</h2>

      <div className="services__list">
        {services.badges.map((el, index) => (
          <div className="services__list--item" key={index}>
            <div>
              <img src={el.icon} alt={el.title} />
            </div>

            <p>{el.title}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Services;
