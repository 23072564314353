export const satisfaction = {
  title: `100% satisfaction<br/>guaranteed`,
  badges: [
    {
      icon: '/assets/images/logos/google_colored.svg',
      alt: 'Google Colored',
      link: '#',
    },
    {
      icon: '/assets/images/logos/fb_colored.svg',
      alt: 'Facebook Colored',
      link: '#',
    },
    {
      icon: '/assets/images/logos/yelp_colored.svg',
      alt: 'Yelp Colored',
      link: '#',
    },
  ],
};
