export const why_we = {
  title: 'Why space moving?',
  badges: [
    {
      icon: '/assets/images/icons/safe_and_secure.svg',
      title: 'Safe and<br/>Secure',
    },
    {
      icon: '/assets/images/icons/delivery_process.svg',
      title: 'Transparent Delivery Process',
    },
    {
      icon: '/assets/images/icons/licensed_and_insured.svg',
      title: 'Fully Licensed & Insured',
    },
    {
      icon: '/assets/images/icons/always_on_time.svg',
      title: 'Always On</br>Time',
    },
  ],
};
