import { counter } from '../../static/content/counter';

import './counter.scss';

const Counter = () => (
  <section className="counter">
    <div className="container">
      <div className="counter__list">
        {counter.badges.map((el, index) => (
          <div className="counter__list--item" key={index}>
            <div>
              <img src={el.icon} alt={el.title} />
            </div>

            <span>{el.count}</span>

            <p>{el.title}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Counter;
