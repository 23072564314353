import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, Seo } from '../components';
import {
  Hero,
  Awards,
  WhyWe,
  GetYourQuote,
  About,
  CallToAction,
  Services,
  Counter,
  Satisfaction,
  Reviews,
  CallUs,
} from '../sections';
import { seo } from '../static/content/seo';

const admin_url = 'https://space-moving.com/wp-json/wp/v2/pages?slug=temecula-landing-page';

const Home = () => {
  const [pageData, setPageData] = React.useState();

  const { isLoading, data, error } = useFetch(admin_url);

  React.useEffect(() => {
    if (data) {
      setPageData(data[0].acf);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout footerText={pageData?.footer_text}>
      <Seo data={seo} />
      <Hero />
      <Awards />
      <WhyWe />
      <GetYourQuote />
      <About data={pageData?.content} />
      <CallToAction />
      <Services />
      <Counter />
      <Satisfaction />
      <Reviews data={pageData?.testimonials} />
      <CallUs />
    </Layout>
  );
};

export default Home;
