import './about.scss';

const About = ({ data }) => (
  <section className="about">
    <div className="container">
      <div className="about__inner">
        <div className="about__content">
          <h2 className="section-title">
            Best Temecula
            <br />
            Moving Company
          </h2>

          <div dangerouslySetInnerHTML={{ __html: data?.paragraph }} />

          <p className="green-text">{data?.green_text}</p>
        </div>

        <div className="about__image" />
      </div>
    </div>
  </section>
);

export default About;
