import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, Page404 } from '../components';

const admin_url = 'https://space-moving.com/wp-json/wp/v2/pages?slug=temecula-landing-page';

const NotFound = () => {
  const [pageData, setPageData] = React.useState();

  const { isLoading, data, error } = useFetch(admin_url);

  React.useEffect(() => {
    if (data) {
      setPageData(data[0].acf);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout footerText={pageData?.footer_text}>
      <section className="not-found">
        <Page404 />
      </section>
    </Layout>
  );
};

export default NotFound;
