import './awards.scss';

const awards = [
  '/assets/images/awards/1.svg',
  '/assets/images/awards/2.svg',
  '/assets/images/awards/3.svg',
  '/assets/images/awards/4.svg',
  '/assets/images/awards/5.svg',
  '/assets/images/awards/6.svg',
  '/assets/images/awards/7.svg',
];

const Awards = () => (
  <section className="awards">
    <div className="container">
      {awards.map((el, index) => (
        <div className="awards__card" key={index}>
          <img src={el} alt={`Award #${index + 1}`} />
        </div>
      ))}
    </div>
  </section>
);

export default Awards;
