export const counter = {
  badges: [
    {
      icon: '/assets/images/icons/counter_clients.svg',
      count: 8042,
      title: 'Clients',
    },
    {
      icon: '/assets/images/icons/counter_miles.svg',
      count: 10932,
      title: 'Miles For Year',
    },
    {
      icon: '/assets/images/icons/counter_tons.svg',
      count: 5721,
      title: 'Tons Of Goods',
    },
  ],
};
