import { why_we as content } from '../../static/content/why-we';

import './whyWe.scss';

const WhyWe = () => (
  <section className="why-we">
    <div className="container">
      <h2 className="section-title">{content.title}</h2>

      <div className="why-we__badges">
        {content.badges.map((el, index) => (
          <div className="why-we__badges--item" key={index}>
            <div>
              <img src={el.icon} alt={el.title} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: el.title }} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default WhyWe;
