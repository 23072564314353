import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ data }) => (
  <Helmet>
    <title>{data?.title}</title>
    <meta name="description" content={data?.description} />
    <meta property="og:title" content={data?.title} />
    <meta property="og:description" content={data?.description} />
  </Helmet>
);

export default Seo;
