import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Book from './pages/Book';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';
import ThankYouPage from './pages/ThankYouPage';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/book-now" element={<Book />} />
      <Route path="/404" element={<NotFound />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />

      <Route path="/thank-you" element={<ThankYouPage />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </BrowserRouter>
);

export default App;
