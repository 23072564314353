import { contacts } from '../../static/content/contacts';

import './callToAction.scss';

const CallToAction = () => (
  <section className="cta">
    <div className="container">
      <div className="cta__inner">
        <div className="cta__inner--image">
          <img src="/assets/images/cta-bg.png" alt="A dog" />
        </div>

        <div className="cta__inner--content">
          <h2 className="section-title">
            Get a free moving
            <br />
            estimate today!
          </h2>

          <a href={`tel:${contacts.phone}`} className="btn phone">
            {contacts.phone}
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
