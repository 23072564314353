import { call_us } from '../../static/content/call-us';
import { contacts } from '../../static/content/contacts';

import './callUs.scss';

const CallUs = () => (
  <section className="call-us">
    <div className="container">
      <h2 className="section-title white" dangerouslySetInnerHTML={{ __html: call_us.title }} />

      <p dangerouslySetInnerHTML={{ __html: call_us.description }} />

      <div className="call-us__links">
        <a href={`tel:${contacts.phone}`} className="btn">
          {contacts.phone}
        </a>
        <span>Call us now!</span>
      </div>

      <div className="call-us__payments">
        {call_us.payments.map((el, index) => (
          <img src={el.icon} alt={el.alt} key={index} />
        ))}
      </div>
    </div>
  </section>
);

export default CallUs;
